


function goBack() {
    window.history.back();
}




   

