/* 

$(function(){
 var shrinkHeader = 100;
  $(window).scroll(function() {
    var scroll = getCurrentScroll();
      if ( scroll >= shrinkHeader ) {
           $('.fixed-header').addClass('shrink');
           $('.secondary-navigation').addClass('hide-me');
            $('.site-header').addClass('margin-top');
        }
        else {
            $('.fixed-header').removeClass('shrink');
            $('.secondary-navigation').removeClass('hide-me');
             $('.site-header').removeClass('margin-top');

        }
  });
function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
    }
});

*/

