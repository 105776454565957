
$(document).ready(function() {

    $('#play-video1').on('click', function(ev) {

        $("#video1")[0].src += "&autoplay=1";
        ev.preventDefault();


        $('#video1').css({
            'opacity': '1'
        });

        $('.responsive-embed').css({
            'display': 'block',
            'opacity': '1',

        });

        $('.home-video1').css({
            'display': 'block',

        });

        $('#stop-video1').css({
            'display': 'block',
            'opacity': '1',

        });

    });

    $('#stop-video1').on('click', function() {

        $('#video1')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video1').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video1').css({
            'display': 'none',
        });

        $('iframe1').css({
            'display': 'none',
        });

        $('#stop-video1').css({
            'display': 'none',
        });

    });


    $('.reveal-overlay').on('click', function() {

        $('#video1')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video1').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video1').css({
            'display': 'none',
        });

        $('iframe1').css({
            'display': 'none',
        });

        $('#stop-video1').css({
            'display': 'none',
        });

    });



    $(document).on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            $('#video1')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

            $('#video1').css({
                'opacity': '0'
            });

            $('.responsive-embed').css({
                'display': 'none',
            });

            $('.home-video1').css({
                'display': 'none',
            });

            $('iframe1').css({
                'display': 'none',
            });

            $('#stop-video1').css({
                'display': 'none',
            });
        }
    });

});






$(document).ready(function() {

    $('#play-video2').on('click', function(ev) {

        $("#video2")[0].src += "&autoplay=1";
        ev.preventDefault();


        $('#video2').css({
            'opacity': '1'
        });

        $('.responsive-embed').css({
            'display': 'block',
            'opacity': '1',

        });

        $('.home-video2').css({
            'display': 'block',

        });

        $('#stop-video2').css({
            'display': 'block',
            'opacity': '1',

        });

    });

    $('#stop-video2').on('click', function(ev) {

        $('#video2')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video2').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video2').css({
            'display': 'none',
        });

        $('iframe2').css({
            'display': 'none',
        });

        $('#stop-video2').css({
            'display': 'none',
        });

    });


    $('.reveal-overlay').on('click', function(ev) {
        $('#video2')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video2').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video2').css({
            'display': 'none',
        });

        $('iframe2').css({
            'display': 'none',
        });

        $('#stop-video2').css({
            'display': 'none',
        });

    });

    $(document).on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            $('#video2')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

            $('#video2').css({
                'opacity': '0'
            });

            $('.responsive-embed').css({
                'display': 'none',
            });

            $('.home-video2').css({
                'display': 'none',
            });

            $('iframe2').css({
                'display': 'none',
            });

            $('#stop-video2').css({
                'display': 'none',
            });
        }
    });

});

$(document).ready(function() {

    $('#play-video4').on('click', function(ev) {

        $("#video4")[0].src += "&autoplay=1";
        ev.preventDefault();


        $('#video4').css({
            'opacity': '1'
        });

        $('.responsive-embed').css({
            'display': 'block',
            'opacity': '1',

        });

        $('.home-video4').css({
            'display': 'block',

        });



        $('#stop-video4').css({
            'display': 'block',
            'opacity': '1',

        });

    });

    $('#stop-video4').on('click', function(ev) {

        $('#video4')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video4').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video4').css({
            'display': 'none',
        });

        $('iframe4').css({
            'display': 'none',
        });

        $('#stop-video4').css({
            'display': 'none',
        });

    });


    $('.reveal-overlay').on('click', function(ev) {

        $('#video4')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video4').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video4').css({
            'display': 'none',
        });

        $('iframe4').css({
            'display': 'none',
        });

        $('#stop-video4').css({
            'display': 'none',
        });


    });



    $(document).on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            $('#video4')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

            $('#video4').css({
                'opacity': '0'
            });

            $('.responsive-embed').css({
                'display': 'none',
            });

            $('.home-video4').css({
                'display': 'none',
            });

            $('iframe4').css({
                'display': 'none',
            });

            $('#stop-video4').css({
                'display': 'none',
            });
        }
    });

});

$(document).ready(function() {

    $('#play-video3').on('click', function(ev) {

        $("#video3")[0].src += "&autoplay=1";
        ev.preventDefault();


        $('#video3').css({
            'opacity': '1'
        });

        $('.responsive-embed').css({
            'display': 'block',
            'opacity': '1',

        });

        $('.home-video3').css({
            'display': 'block',

        });



        $('#stop-video3').css({
            'display': 'block',
            'opacity': '1',

        });

    });

    $('#stop-video3').on('click', function() {

        $('#video3')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video3').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video3').css({
            'display': 'none',
        });

//            $('iframe3').css({
//                'display': 'none',
//            });

        $('#stop-video3').css({
            'display': 'none',
        });

    });




    $('.reveal-overlay').on('click', function(ev) {

        $('#video3')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

        $('#video3').css({
            'opacity': '0'
        });

        $('.responsive-embed').css({
            'display': 'none',
        });

        $('.home-video3').css({
            'display': 'none',
        });

        $('#stop-video3').css({
            'display': 'none',
        });


    });

    $(document).on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            $('#video3')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

            $('#video3').css({
                'opacity': '0'
            });

            $('.responsive-embed').css({
                'display': 'none',
            });

            $('.home-video3').css({
                'display': 'none',
            });

            $('iframe3').css({
                'display': 'none',
            });

            $('#stop-video3').css({
                'display': 'none',
            });
        }
    });

});