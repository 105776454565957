

$('#slide-out').click( function() {
    $('.slide-out-container').css({
        'width': '80%'
    });
    $('.slide-out-container .button').css({
        'opacity': '1',
        'width': '100%',
        'display': 'block'

    });
    $('.slide-out-container .search-container').css({
        'width': '100%',
        'float': 'none'
    });
    $('.slide-out-container .search-bar-input').css({
        'width': '75%'
    
    });

    $('.slide-out-container .search-container-input').css({
        'background-color': '#ffffff',
        'color': '#000000',
        'text-align': 'left'

    
    });

    
});